import { HStack, PBold } from "@fm-frontend/uikit";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import { Instrument } from "hooks/useInstruments";
import styled from "styled-components";
import { InstrumentIcon } from "../../../InstrumentIcon";
import { useSearchQuery } from "../../../searchQueryContext";

const Container = styled(HStack)`
    gap: 6px;
    white-space: nowrap;
    flex-wrap: nowrap;
`;

const InstrumentContainer = styled(HStack)`
    gap: 6px;
`;

export const InstrumentNameCell = ({ instrument }: { instrument: Instrument }) => {
    const searchQuery = useSearchQuery();

    return (
        <Container>
            <InstrumentContainer>
                <InstrumentIcon {...instrument} />
                <PBold>
                    {getLabelWithHighlightedSearch(instrument.instrumentName, searchQuery)}
                </PBold>
            </InstrumentContainer>
        </Container>
    );
};

import { CoinIcon } from "components/CoinIcon";
import styled from "styled-components";

const InstrumentIcons = styled.div<{ $coinSize: number }>`
    width: ${(p) => p.$coinSize + (p.$coinSize * 14) / 16}px;
    height: ${(p) => p.$coinSize}px;
    position: relative;

    img {
        top: 1px;
        position: absolute;
        border-radius: 8px;
    }

    img:first-child {
        left: ${(p) => (p.$coinSize * 14) / 16}px;
        -webkit-mask-image: radial-gradient(
            circle closest-corner at -37% center,
            rgba(0, 0, 0, 0) 88%,
            rgba(0, 0, 0, 1) 0
        );
    }
`;

export const InstrumentIcon = ({
    assetCurrency,
    balanceCurrency,
    className,
    coinSize = 16,
}: {
    assetCurrency: string;
    balanceCurrency: string;
    className?: string;
    coinSize?: number;
}) => (
    <InstrumentIcons className={className} $coinSize={coinSize}>
        <CoinIcon coin={balanceCurrency} size={coinSize} />
        <CoinIcon coin={assetCurrency} size={coinSize} />
    </InstrumentIcons>
);

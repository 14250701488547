import { EXPAND_TABLE_COLUMN_KEY, Icons } from "@fm-frontend/uikit";
import { createColumnHelper } from "@tanstack/react-table";
import { TableRowExpandContainer } from "feature/history/trades/Content";
import { TradeHistory } from "feature/history/types";
import { DateTimeCell } from "./cells/DateTimeCell";
import { InstrumentCell } from "./cells/InstrumentCell";
import { CpIdCell } from "./cells/LpIdCell";
import { PriceCell } from "./cells/PriceCell";
import { SideCell } from "./cells/SideCell";
import { SizeCell } from "./cells/SizeCell";

const columnHelper = createColumnHelper<TradeHistory>();

const toggleColumn = columnHelper.display({
    id: EXPAND_TABLE_COLUMN_KEY,
    header: ({ table }) => {
        const isAllRowsExpanded = table.getIsAllRowsExpanded();

        return (
            <TableRowExpandContainer onClick={table.getToggleAllRowsExpandedHandler()}>
                {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
            </TableRowExpandContainer>
        );
    },
    cell: ({ row }) => {
        return !row.getCanExpand() ? null : row.getIsExpanded() ? (
            <Icons.Collapse />
        ) : (
            <Icons.Expand />
        );
    },
    meta: {
        headerStyleProps: {
            width: "32px",
            maxWidth: "32px",
            padding: "0 0 0 11px",
        },
        cellStyleProps: {
            width: "32px",
            maxWidth: "32px",
            padding: "0 0 0 11px",
        },
        sticky: true,
    },
});
export const tableColumns = [
    toggleColumn,
    columnHelper.display({
        id: "time",
        cell: (info) => <DateTimeCell date={info.row.original.date} />,
        header: "Time",
        meta: {
            cellStyleProps: {
                width: "48px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.display({
        id: "instrument",
        cell: (info) => {
            return (
                <InstrumentCell
                    instrument={info.row.original.instrument}
                    isSubrow={info.row.original.isSubrow}
                />
            );
        },
        header: "Instrument",
        meta: {
            cellStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.display({
        id: "side",
        cell: (info) => (
            <SideCell side={info.row.original.side} isSubrow={info.row.original.isSubrow} />
        ),
        header: "Side",
        meta: {
            cellStyleProps: {
                width: "32px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),

    columnHelper.display({
        id: "size",
        cell: (info) => {
            return (
                <SizeCell
                    size={info.row.original.size}
                    instrument={info.row.original.instrument}
                    isExpandableRow={info.row.getCanExpand()}
                />
            );
        },
        header: "Size",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.display({
        id: "price",
        cell: (info) => (
            <PriceCell price={info.row.original.price} isExpandableRow={info.row.getCanExpand()} />
        ),
        header: "Price",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.display({
        id: "lpId",
        cell: (info) => (
            <CpIdCell cpId={info.row.original.cpId} isExpandableRow={info.row.getCanExpand()} />
        ),
        header: "LP",
        meta: {
            cellStyleProps: {
                maxWidth: "46px",
            },
            headerStyleProps: {
                maxWidth: "46px",
                textAlign: "right",
            },
        },
    }),
];

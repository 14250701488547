import { P, PBold } from "@fm-frontend/uikit";
import { TradeHistory } from "feature/history/types";
import { useInstruments } from "hooks";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import React from "react";

type SizeProps = Pick<TradeHistory, "size" | "instrument"> & {
    isExpandableRow?: boolean;
};

export const SizeCell: React.FC<SizeProps> = ({ size, instrument, isExpandableRow }) => {
    const { intrumentsByNameObj } = useInstruments();
    const { toFormattedDecimalStringByAsset } = useSize64AssetFormatHelpers();

    if (size === undefined || !instrument) {
        return null;
    }

    const Element = isExpandableRow ? PBold : P;

    return (
        <Element>
            {toFormattedDecimalStringByAsset(size, intrumentsByNameObj[instrument]?.assetCurrency)}
        </Element>
    );
};

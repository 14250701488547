import { Icons, P, PBold } from "@fm-frontend/uikit";
import styled, { useTheme } from "styled-components";
import { Banner as BasicBanner } from "./styled";

const Banner = styled(BasicBanner)`
    bottom: 50px;
`;

export const NoInstrumentBanner = () => {
    const { colors } = useTheme();

    return (
        <Banner>
            <Icons.Attention color={colors.ui72} />
            <PBold color={colors.ui72}>Select an instrument</PBold>
            <P color={colors.ui72}>to display asset-specific information</P>
        </Banner>
    );
};

import {
    Accordion,
    ACTIONS_TABLE_COLUMN_KEY,
    HStack,
    IconButton,
    Icons,
    Tooltip,
} from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { useAuthorizedClientInfo } from "feature/trade/hooks/useAuthorizedClientInfo";
import { useCreatePrefilledTransactionOrRequest } from "hooks";
import { useMemo } from "react";
import { useAuthorizedPositionsTableData } from "store/usePositionsGrouped";
import { Spacer } from "style";
import styled from "styled-components";
import { CommonPositionsAccordion } from "./CommonPositionsAccordion";
import { PositionsAccordionContent } from "./PositionsAccordionContent";
import { columnHelper } from "./PositionsAccordionContent/PositionsTable/table";
import { isSubrow } from "./PositionsAccordionContent/utils";

const PositionsAccordionHeader = styled(HStack)`
    gap: 8px;
    align-items: center;
`;

const { HRP_MASTER_ID } = getEnv();

export const AuthorizedPositionsAccordion = ({
    authorizedClientId,
}: {
    authorizedClientId: AuthorizedClientId;
}) => {
    const { totalUSD, groupedPositionsData, isLoading } = useAuthorizedPositionsTableData({
        groupBy: "counterparty",
        includeSettlementOrders: true,
        authorizedClientId,
    });
    const noPositions = !isLoading && Object.keys(groupedPositionsData).length === 0;

    const authorizedClientInfo = useAuthorizedClientInfo(authorizedClientId);
    const authorizedTradingTitle = (
        authorizedClientInfo ? `open positions of ${authorizedClientInfo.username}` : ""
    ).toUpperCase();

    const header = (
        <PositionsAccordionHeader>
            <Accordion.Title>{authorizedTradingTitle}</Accordion.Title>
            {noPositions && (
                <>
                    <Spacer />
                    <Accordion.EmptyLabel>No open positions</Accordion.EmptyLabel>
                </>
            )}
        </PositionsAccordionHeader>
    );

    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    const actionColumn = useMemo(
        () =>
            columnHelper.accessor((row) => row, {
                id: ACTIONS_TABLE_COLUMN_KEY,
                header: "",
                cell: (rowData) => {
                    const rowValue = rowData.getValue();

                    if (isSubrow(rowValue)) {
                        const { cpId, asset, value } = rowValue;

                        if (cpId === HRP_MASTER_ID || authorizedClientId === null) {
                            return null;
                        }

                        return (
                            <Tooltip content="Settle">
                                <IconButton
                                    variant="primary"
                                    Icon={Icons.ArrowUp}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        createPrefilledTransactionOrRequest({
                                            cpId: authorizedClientId,
                                            asset,
                                            value: value * -1n,
                                        });
                                    }}
                                />
                            </Tooltip>
                        );
                    }

                    return null;
                },
                meta: {
                    cellStyleProps: {
                        width: "40px",
                        textAlign: "right",
                        padding: "0 8px 0 0",
                    },
                },
            }),
        [authorizedClientId, createPrefilledTransactionOrRequest],
    );

    const content = (
        <PositionsAccordionContent
            groupBy="counterparty"
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            actionColumn={actionColumn}
        />
    );

    return (
        <CommonPositionsAccordion
            totalUSD={totalUSD}
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            content={content}
            header={header}
        />
    );
};

import { Icons, PlainButton, Popover, PSmall, PSmallBold, VStack } from "@fm-frontend/uikit";
import { transition } from "@fm-frontend/utils";
import { FC } from "react";
import styled from "styled-components";

const TooltipContent = styled(VStack)`
    gap: 6px;
    padding: 6px 8px;
    width: 240px;

    background-color: ${(p) => p.theme.colors.ui96};
    color: ${(p) => p.theme.colors.uiWhite100};
    border-radius: 8px;
    max-width: 280px;
    z-index: 10;
    white-space: break-spaces;
    overflow-wrap: break-word;
    hyphens: none;
    ${(p) => transition(p.theme.transitions.default)}
`;

const Title = styled(PSmallBold)`
    color: ${(p) => p.theme.colors.uiWhite100};
`;

const Description = styled(PSmall)`
    color: ${(p) => p.theme.colors.uiWhite52};
`;

const PlainButtonStyled = styled(PlainButton)`
    color: ${(p) => p.theme.colors.brand100};
    padding-left: 0;
`;

export const TooltipContainer = styled.div`
    position: relative;
    pointer-events: all;
    display: inline-flex;
`;

export const ClientSelectTooltip: FC<{ initialActive: boolean; onClose: () => void }> = ({
    initialActive,
    onClose,
    children,
}) => {
    return (
        <Popover
            isOpen={initialActive}
            padding={6}
            positions={["bottom"]}
            align="center"
            content={
                <TooltipContent>
                    <Icons.ArrowUp />
                    <Title>Choose a client for authorized trading</Title>
                    <Description>
                        The interface below will display information based on the selected client,
                        including a list of instruments, positions, and recent trades.
                        <br />
                        The risk profile section shows your settings for this client.
                    </Description>
                    <PlainButtonStyled size="small" type="button" onClick={onClose}>
                        Close
                    </PlainButtonStyled>
                </TooltipContent>
            }
        >
            <TooltipContainer>{children}</TooltipContainer>
        </Popover>
    );
};

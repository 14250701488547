import {
    FieldGroup,
    HStack,
    IconButton,
    Icons,
    PBold,
    Tooltip,
    TradeAmountInput,
    VStack,
} from "@fm-frontend/uikit";
import { NumericInput } from "@fm-frontend/uikit/src/components/inputs/Input/Input";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { MAX_FRACTION_DIGITS, ValueConvert, ValueParse } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useCurrenciesPositions } from "feature/addresses/hooks";
import { createNotification } from "feature/app";
import { InstrumentIcon } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentIcon";
import { AuthorizedClientId } from "feature/trade/hooks/useAuthorizedClientId";
import { getAuthorizedLastOrdersSWRKey } from "feature/trade/hooks/useAuthorizedLastOrders";
import { useConditionalTradingBookSubscription } from "feature/trade/hooks/useConditionalTradingBookSubscription";
import { useLastOrdersSWRKey } from "feature/trade/hooks/useLastOrders";
import { ToleranceInput } from "feature/trade/trading/ToleranceInput";
import { TradingSide } from "feature/trade/trading/types";
import { DISPLAY_TRADING_LAST_TRADES_LIMIT } from "feature/trade/trading/utils";
import { useSelector } from "hooks";
import { getAssetPlaceholder, useAssetFractionDigits } from "hooks/useAssetFractionDigits";
import { Subscription, useEventReceiver } from "hooks/useEventEmitter";
import { useInstrumentInfo } from "hooks/useInstrumentInfo";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
    addOrder,
    AddOrderParams,
    authorizedAddOrder,
    AuthorizedAddOrderParams,
    AuthorizedTypes,
    OrderSides,
    OrderTypes,
} from "services/tradingService";
import { useUsdPrices } from "store/hooks";
import styled from "styled-components";
import { useSWRConfig } from "swr";
import { Book, Level } from "types";
import { scrollToElement } from "utils";
import { AnySchema, number, object, string } from "yup";
import { AuthorizedClientTileInfo } from "../ClientTileInfo";
import { CopyTileActions } from "../CopyTileActions";
import {
    InfoSpread,
    TileActions,
    TileContent,
    TileError,
    TileHeader,
    TileInfoSection,
    TileLayout,
    TradingButton,
} from "./components";
import { useTradingButtonsActivationState } from "./useTradingButtonsActivationState";
import { calculateEquivalentVolumeOrSize } from "./utils/calculateEquivalentVolumeOrSize";
import { calculateSpread } from "./utils/calculateSpread";
import { calculateTotalAmountAsset } from "./utils/calculateTotalAmountAsset";
import { calculateTotalVolumeOrSize } from "./utils/calculateTotalVolumeOrSize";
import { calculateTradingSide } from "./utils/calculateTradingSide";
import { getBestMarkerPrice } from "./utils/getBestMarkerPrice";
import { getBookLevels } from "./utils/getBookLevels";
import {
    getDisabledBuyActionState,
    getDisabledSellActionState,
} from "./utils/getDisabledTradingActionState";
import { getFormFieldData } from "./utils/getFormFieldData";
import { getNumericInputPattern } from "./utils/getNumericInputPattern";
import { getPriceToDisplay } from "./utils/getPriceToDisplay";
import { getPriceWithTolerance } from "./utils/getPriceWithTolerance";
import { getWeightedAveragePrice } from "./utils/getWeightedAveragePrice";
import { handleNumberInputChange } from "./utils/handleNumberInputChange";
import { isLimitOrderType } from "./utils/isLimitOrderType";
import {
    decrementTolerance,
    incrementTolerance,
    MAX_TOLERANCE_PERCENT,
    TOLERANCE_PERCENT_SIGNIFICANT_DIGITS,
} from "./utils/tolerance";

const Container = styled.div`
    position: relative;
`;

const Wrapper = styled.div`
    overflow: hidden;
    border-radius: 12px;
`;

const ErrorsContainer = styled(VStack)`
    gap: 6px;
    align-items: center;
`;

const typeTakerOptions: DropdownOption<OrderTypes>[] = [
    {
        text: "Market IOC",
        value: "marketIOC",
    },
    {
        text: "Market FOK",
        value: "marketFOK",
    },
    {
        text: "Limit IOC",
        value: "limitIOC",
    },
    {
        text: "Limit FOK",
        value: "limitFOK",
    },
];

const authorizedTradeOptions: DropdownOption<AuthorizedTypes>[] = [
    {
        text: "Authorized trade",
        value: AuthorizedTypes.AuthorizedTrade,
    },
    {
        text: "Authorized liquidation",
        value: AuthorizedTypes.AuthorizedLiquidation,
    },
];

type TileInputs = {
    type: OrderTypes;
    authorizedType: AuthorizedTypes;
    price: string | number;
    sizeOrVolume: string | number;
    tolerance: string | number;
};

const getTradingTileSchema = ({ tradingSide }: { tradingSide: TradingSide }) => {
    const volumeOrSizeName = `${tradingSide === "size" ? "Size" : "Volume"}`;

    return object<Record<keyof TileInputs, AnySchema>>().shape({
        type: string().required("Type is required"),
        sizeOrVolume: number()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .nullable()
            .typeError(`${volumeOrSizeName} is invalid`)
            .required(`${volumeOrSizeName} is required`)
            .moreThan(0, `${volumeOrSizeName} must be more that 0`),
        price: number()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .nullable()
            .moreThan(0, "Price must be more that 0"),
        tolerance: number()
            .transform((curr, orig) => (orig === "" ? null : curr))
            .nullable()
            .moreThan(0, `Tolerance must be more than 0%`)
            .max(MAX_TOLERANCE_PERCENT, `Tolerance must be less than ${MAX_TOLERANCE_PERCENT}%`),
    });
};

const getPricesData = ({
    isSell,
    tradingSide,
    tradingInputs,
    bookLevels,
}: {
    isSell: boolean;
    tradingSide: TradingSide;
    tradingInputs: TileInputs;
    bookLevels: Level[];
}): {
    appliedPrice: bigint | null;
    marketPrice: bigint | null;
    waPrice: bigint | null;
    priceWithTolerance: bigint | null;
} => {
    const isBookEmpty = bookLevels.length === 0;

    if (isBookEmpty) {
        return {
            appliedPrice: null,
            marketPrice: null,
            waPrice: null,
            priceWithTolerance: null,
        };
    }

    const bestMarketPrice = getBestMarkerPrice(bookLevels);

    const isValidSizeOrVolume = tradingInputs.sizeOrVolume !== "";
    const sizeOrVolume = isValidSizeOrVolume
        ? ValueParse.size(String(tradingInputs.sizeOrVolume))
        : null;

    const isValidPrice = tradingInputs.price !== "";
    const priceInput = isValidPrice ? ValueConvert.size(tradingInputs.price) : null;

    const isToleranceValid = tradingInputs.tolerance !== "";
    let tolerance = isToleranceValid ? ValueConvert.percent(tradingInputs.tolerance) : null;
    tolerance = tolerance !== null && isSell ? tolerance * -1 : tolerance;

    const waPrice = getWeightedAveragePrice({
        tradingSide,
        orderBookLevels: bookLevels,
        sizeOrVolume,
    });

    let appliedPrice = priceInput || waPrice;
    const priceWithTolerance = getPriceWithTolerance(appliedPrice, tolerance);
    appliedPrice = priceWithTolerance || appliedPrice;

    return {
        appliedPrice,
        marketPrice: bestMarketPrice,
        waPrice: waPrice,
        priceWithTolerance: priceWithTolerance,
    };
};

const DEFAULT_TRADING_TILE_INPUTS: TileInputs = {
    type: "marketIOC",
    authorizedType: AuthorizedTypes.AuthorizedTrade,
    sizeOrVolume: "",
    price: "",
    tolerance: "",
};

type TradingTileProps = {
    instrumentName: string;
    selectedBook: Book;
    resetErrors: () => void;
    isActive?: boolean;
    isTopFavoriteInstrument: boolean;
    authorizedClientId?: AuthorizedClientId;
    removeInstrument: (instrumentName: string) => void;
    selectInstrument: (instrumentName: string) => void;
    selectedInstrument: string | undefined;
};
export const TradingTile: FC<TradingTileProps> = ({
    instrumentName,
    selectedBook,
    resetErrors,
    isTopFavoriteInstrument,
    isActive = false,
    authorizedClientId,
    removeInstrument,
    selectInstrument,
    selectedInstrument,
}) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const shouldBeSubscribed = isActive || isTopFavoriteInstrument;
    useConditionalTradingBookSubscription(instrumentName, shouldBeSubscribed, authorizedClientId);
    const dispatch = useDispatch();
    const authorizedLastOrdersSWRKey = getAuthorizedLastOrdersSWRKey(
        DISPLAY_TRADING_LAST_TRADES_LIMIT,
        authorizedClientId,
    );
    const lastOrdersSWRKey = useLastOrdersSWRKey(DISPLAY_TRADING_LAST_TRADES_LIMIT);
    const { mutate } = useSWRConfig();
    const { showRealTimeNotification } = useSelector((state) => state.settings);
    const { priceObj } = useUsdPrices();

    const { baseAsset = "", quoteAsset = "" } = useInstrumentInfo(instrumentName);
    const [tradeAsset, setTradeAsset] = useState(baseAsset);
    useEffect(() => {
        setTradeAsset(baseAsset);
    }, [baseAsset]);

    const tradingSide = calculateTradingSide({ tradeAsset, baseAsset });

    const currenciesPositions = useCurrenciesPositions(authorizedClientId);
    let quotePosition = currenciesPositions[quoteAsset]?.value;
    let basePosition = currenciesPositions[baseAsset]?.value;

    if (authorizedClientId && quotePosition) {
        quotePosition = BigInt(quotePosition) * -1n;
    }
    if (authorizedClientId && basePosition) {
        basePosition = BigInt(basePosition) * -1n;
    }

    const { fractionDigits: tradeAssetFractionDigits, placeholder: tradeAssetPlaceholder } =
        useAssetFractionDigits(tradeAsset);

    const {
        control,
        formState: { errors, dirtyFields, isValid, isDirty, isSubmitting },
        watch,
        handleSubmit,
        setValue,
        resetField,
        reset,
        setError,
        trigger,
        clearErrors,
    } = useForm<TileInputs>({
        mode: "onChange",
        defaultValues: {
            ...DEFAULT_TRADING_TILE_INPUTS,
        },
        resolver: yupResolver(
            getTradingTileSchema({
                tradingSide,
            }),
        ),
    });

    const tradingInputs = watch();

    const {
        state: tradingActionsState,
        reset: resetTradingActions,
        activateSell: activateSellAction,
        activateBuy: activateBuyAction,
    } = useTradingButtonsActivationState({
        canBeActivated: trigger,
    });

    useEffect(() => {
        if (!isActive) {
            resetTradingActions();
            clearErrors();
        }
        if (isActive && isDirty) {
            trigger();
        }
    }, [isActive, resetTradingActions, trigger, clearErrors, isDirty]);

    useEffect(() => {
        if (isActive && elementRef.current) {
            scrollToElement(elementRef.current);
        }
    }, [isActive]);

    useEffect(() => {
        resetField("sizeOrVolume");
    }, [tradeAsset, resetField]);

    useEffect(() => {
        if (!isValid) {
            resetTradingActions();
        }
    }, [isValid, resetTradingActions]);

    const receiverParams = useMemo<{ feed: string; fn: Subscription }>(() => {
        return {
            feed: "trading",
            fn: (msg) => {
                if (msg?.type === "reset") {
                    resetTradingActions();
                    reset({
                        ...DEFAULT_TRADING_TILE_INPUTS,
                        authorizedType: tradingInputs.authorizedType,
                        type: tradingInputs.type,
                    });
                    return;
                }
                if (msg?.type === "setPrice" && msg.instrument === instrumentName) {
                    setValue("price", msg.value, {
                        shouldValidate: true,
                    });

                    setValue("type", "limitIOC", {
                        shouldValidate: true,
                    });
                }
            },
        };
    }, [setValue, reset, resetTradingActions, instrumentName, tradingInputs]);
    useEventReceiver(receiverParams);

    const successHandler = useCallback(() => {
        if (showRealTimeNotification) {
            dispatch(
                createNotification({
                    type: "success",
                    content: `The ${instrumentName} trade has been done.`,
                }),
            );
        }
        if (authorizedClientId) {
            mutate(authorizedLastOrdersSWRKey);
        } else {
            mutate(lastOrdersSWRKey);
        }

        resetErrors();
    }, [
        dispatch,
        instrumentName,
        showRealTimeNotification,
        mutate,
        resetErrors,
        authorizedLastOrdersSWRKey,
        lastOrdersSWRKey,
    ]);

    const isPriceFieldAvailable = isLimitOrderType(tradingInputs.type) && isActive;
    const isToleranceFieldAvailable = isLimitOrderType(tradingInputs.type) && isActive;

    const { bidLevels, askLevels } = getBookLevels({
        book: selectedBook,
    });

    const { isValid: isSizeOrVolumeValid } = getFormFieldData<TileInputs>({
        field: "sizeOrVolume",
        dirtyFields,
        errors,
    });
    const sizeOrVolume = isSizeOrVolumeValid ? ValueConvert.size(tradingInputs.sizeOrVolume) : null;

    const {
        appliedPrice: appliedSellPrice,
        priceWithTolerance: sellPriceWithTolerance,
        waPrice: sellWAPrice,
        marketPrice: sellMarketPrice,
    } = getPricesData({
        isSell: true,
        tradingSide,
        tradingInputs,
        bookLevels: bidLevels,
    });
    const {
        appliedPrice: appliedBuyPrice,
        priceWithTolerance: buyPriceWithTolerance,
        waPrice: buyWAPrice,
        marketPrice: buyMarketPrice,
    } = getPricesData({
        isSell: false,
        tradingSide,
        tradingInputs,
        bookLevels: askLevels,
    });

    const { spreadInPercentage } = calculateSpread({
        bidPrice: sellWAPrice || sellMarketPrice,
        askPrice: buyWAPrice || buyMarketPrice,
    });
    const totalVolumeOrSize = calculateTotalVolumeOrSize({
        tradingSide,
        sizeOrVolume: sizeOrVolume,
        buyPrice: buyWAPrice,
        sellPrice: sellWAPrice,
    });

    const totalVolumeOrSizeAsset = calculateTotalAmountAsset({
        tradingSide,
        baseAsset,
        quoteAsset,
    });
    const equivalentVolumeOrSize = calculateEquivalentVolumeOrSize({
        totalVolumeOrSize,
        usdPrice: priceObj[totalVolumeOrSizeAsset],
    });

    const disabledBuyActionState = getDisabledBuyActionState({
        isDisabled: !isValid && isDirty,
        buyMarketPrice,
        buyWAPrice,
        price: appliedBuyPrice,
    });
    const disabledSellActionState = getDisabledSellActionState({
        isDisabled: !isValid && isDirty,
        sellMarketPrice,
        sellWAPrice,
        price: appliedSellPrice,
    });

    const buyPriceToDisplay = getPriceToDisplay({
        marketPrice: buyMarketPrice,
        waPrice: buyWAPrice,
    });
    const sellPriceToDisplay = getPriceToDisplay({
        marketPrice: sellMarketPrice,
        waPrice: sellWAPrice,
    });

    const handleTradeAction = async (
        formInputs: TileInputs,
        side: OrderSides,
        price: bigint | null,
    ) => {
        try {
            if (authorizedClientId) {
                const addRequest: AuthorizedAddOrderParams = {
                    instrument: instrumentName,
                    side: side,
                    type: formInputs.type,
                    liquidation:
                        formInputs.authorizedType === AuthorizedTypes.AuthorizedLiquidation,
                    clientId: authorizedClientId,
                };

                if (isLimitOrderType(formInputs.type)) {
                    addRequest.price = price;
                }

                if (tradingSide === "size") {
                    addRequest.size = ValueConvert.size(formInputs.sizeOrVolume);
                }
                if (tradingSide === "volume") {
                    addRequest.volume = ValueConvert.size(formInputs.sizeOrVolume);
                }
                await authorizedAddOrder(addRequest);
            } else {
                const addRequest: AddOrderParams = {
                    instrument: instrumentName,
                    side: side,
                    type: formInputs.type,
                };

                if (isLimitOrderType(formInputs.type)) {
                    addRequest.price = price;
                }

                if (tradingSide === "size") {
                    addRequest.size = ValueConvert.size(formInputs.sizeOrVolume);
                }
                if (tradingSide === "volume") {
                    addRequest.volume = ValueConvert.size(formInputs.sizeOrVolume);
                }
                await addOrder(addRequest);
            }
            successHandler();
        } catch (e) {
            setError("root.api", {
                message: String(e),
            });

            setTimeout(() => {
                setError("root.api", {
                    message: "",
                });
                trigger();
            }, 2000);
        }
    };
    const handleSellAction = async (formInputs: TileInputs) => {
        await handleTradeAction(formInputs, "ask", appliedSellPrice);
    };
    const handleBuyAction = async (formInputs: TileInputs) => {
        await handleTradeAction(formInputs, "bid", appliedBuyPrice);
    };

    const handleOrderTypeChange = (newLimitOrderType: OrderTypes) => {
        const isLimitOrderTypeChanged =
            isLimitOrderType(newLimitOrderType) !== isLimitOrderType(tradingInputs.type);

        if (isLimitOrderTypeChanged) {
            resetField("tolerance");
            resetField("price");
        }

        setValue("type", newLimitOrderType, {
            shouldValidate: true,
        });
    };
    const handleAuthorizedTypeChange = (newAuthorizedType: AuthorizedTypes) => {
        setValue("authorizedType", newAuthorizedType, {
            shouldValidate: false,
        });
    };

    return (
        <Container ref={elementRef}>
            <TileLayout
                $isActive={isActive}
                onClick={() => {
                    if (selectedInstrument !== instrumentName) {
                        selectInstrument(instrumentName);
                    }
                }}
            >
                <Wrapper>
                    <TileHeader $isActive={isActive}>
                        <InstrumentIcon assetCurrency={baseAsset} balanceCurrency={quoteAsset} />
                        <PBold>{instrumentName}</PBold>
                        <Tooltip content="Remove">
                            <IconButton
                                type="button"
                                variant="plain"
                                Icon={Icons.X}
                                onClick={() => removeInstrument(instrumentName)}
                            />
                        </Tooltip>
                    </TileHeader>
                    <TileContent $isActive={isActive}>
                        <HStack spacing={6}>
                            {Boolean(authorizedClientId) && (
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <SingleDropdown
                                            value={field.value}
                                            onChange={handleAuthorizedTypeChange}
                                            renderTrigger={(trigger) => (
                                                <SingleDropdown.Trigger
                                                    {...trigger}
                                                    size="medium"
                                                    variant="minimum"
                                                >
                                                    <SingleDropdown.TriggerEssence
                                                        {...trigger}
                                                        option={trigger.selectedOption}
                                                        size="medium"
                                                    />
                                                </SingleDropdown.Trigger>
                                            )}
                                            options={authorizedTradeOptions}
                                            placeholder="Select"
                                            align="start"
                                            fullWidth
                                        >
                                            <SingleDropdown.BasicSheet
                                                options={authorizedTradeOptions}
                                                size="small"
                                            />
                                        </SingleDropdown>
                                    )}
                                    name="authorizedType"
                                />
                            )}
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <SingleDropdown
                                        value={field.value}
                                        onChange={handleOrderTypeChange}
                                        renderTrigger={(trigger) => (
                                            <SingleDropdown.Trigger
                                                {...trigger}
                                                size="medium"
                                                variant="minimum"
                                            >
                                                <SingleDropdown.TriggerEssence
                                                    {...trigger}
                                                    option={trigger.selectedOption}
                                                    size="medium"
                                                />
                                            </SingleDropdown.Trigger>
                                        )}
                                        options={typeTakerOptions}
                                        placeholder="Select"
                                        align="end"
                                        fullWidth
                                    >
                                        <SingleDropdown.BasicSheet
                                            options={typeTakerOptions}
                                            size="small"
                                        />
                                    </SingleDropdown>
                                )}
                                name="type"
                            />
                        </HStack>
                        <FieldGroup>
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <TradeAmountInput
                                        {...field}
                                        autoComplete="off"
                                        decimalScale={tradeAssetFractionDigits}
                                        assetCurrency={baseAsset}
                                        balanceCurrency={quoteAsset}
                                        selectedTradeCurrency={tradeAsset}
                                        onTradeCurrencyChange={setTradeAsset}
                                        error={errors?.sizeOrVolume?.message}
                                        showError={false}
                                        placeholder={tradeAssetPlaceholder}
                                    />
                                )}
                                name="sizeOrVolume"
                            />
                            {isPriceFieldAvailable && (
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <NumericInput
                                            {...field}
                                            variant="simple"
                                            autoComplete="off"
                                            onChange={(event) => {
                                                if (
                                                    event.target.value &&
                                                    typeof event.target.value === "string"
                                                ) {
                                                    event.target.value = [...event.target.value]
                                                        .filter((char) => char !== ",")
                                                        .join("");
                                                }
                                                field.onChange(event);
                                            }}
                                            label="Price"
                                            _size="medium"
                                            placeholder={getAssetPlaceholder(MAX_FRACTION_DIGITS)}
                                            error={errors?.price?.message}
                                            showError={false}
                                            decimalScale={MAX_FRACTION_DIGITS}
                                        />
                                    )}
                                    name="price"
                                />
                            )}
                        </FieldGroup>
                        {isToleranceFieldAvailable && (
                            <Controller
                                control={control}
                                render={({ field }) => (
                                    <ToleranceInput
                                        {...field}
                                        onIncrement={() => {
                                            setValue(
                                                "tolerance",
                                                incrementTolerance(tradingInputs.tolerance),
                                                {
                                                    shouldValidate: true,
                                                },
                                            );
                                        }}
                                        onDecrement={() => {
                                            setValue(
                                                "tolerance",
                                                decrementTolerance(tradingInputs.tolerance),
                                                {
                                                    shouldValidate: true,
                                                },
                                            );
                                        }}
                                        autoComplete="off"
                                        onChange={(event) =>
                                            handleNumberInputChange(event, field.onChange, {
                                                restrictionPattern: getNumericInputPattern(
                                                    TOLERANCE_PERCENT_SIGNIFICANT_DIGITS,
                                                ),
                                            })
                                        }
                                        label="Tolerance"
                                        labelHelper="The acceptable deviation from a set price. The limit order will be adjusted to include this tolerance in the price."
                                        _size="medium"
                                        placeholder="0.00"
                                        error={errors?.tolerance?.message}
                                        showError={false}
                                    />
                                )}
                                name="tolerance"
                            />
                        )}
                    </TileContent>
                    <TileInfoSection
                        isActive={isActive}
                        basePosition={basePosition}
                        baseAsset={baseAsset}
                        quoteAsset={quoteAsset}
                        quotePosition={quotePosition}
                        equivalentAmount={equivalentVolumeOrSize}
                        totalAmount={totalVolumeOrSize}
                        totalAsset={totalVolumeOrSizeAsset}
                        tradingSide={tradingSide}
                    />
                    <CopyTileActions
                        isTileActive={isActive}
                        authorizedClientId={authorizedClientId}
                        tradingSide={tradingSide}
                        instrument={instrumentName}
                        baseAsset={baseAsset}
                        quoteAsset={quoteAsset}
                        sizeOrVolume={sizeOrVolume}
                        totalVolumeOrSize={totalVolumeOrSize}
                        equivalentVolumeOrSize={equivalentVolumeOrSize}
                        sellPrice={appliedSellPrice || sellMarketPrice}
                        buyPrice={appliedBuyPrice || buyMarketPrice}
                    />
                    <TileActions>
                        <TradingButton
                            isSubmitting={isSubmitting}
                            variant="sell"
                            tooltipContent={disabledSellActionState.reason}
                            onClick={handleSubmit((formInputs) => handleSellAction(formInputs))}
                            disabled={disabledSellActionState.isDisabled}
                            isActivated={tradingActionsState.sellActive}
                            onActivate={activateSellAction}
                        >
                            <TradingButton.Essence
                                isSell
                                isTileSubscribed={shouldBeSubscribed}
                                title={`Sell ${baseAsset}`}
                                priceToDisplay={sellPriceToDisplay}
                                priceWithTolerance={sellPriceWithTolerance}
                            />
                        </TradingButton>
                        <InfoSpread isActive={isActive} spreadInPercentage={spreadInPercentage} />
                        <TradingButton
                            isSubmitting={isSubmitting}
                            variant="buy"
                            tooltipContent={disabledBuyActionState.reason}
                            onClick={handleSubmit((formInputs) => handleBuyAction(formInputs))}
                            disabled={disabledBuyActionState.isDisabled}
                            isActivated={tradingActionsState.buyActive}
                            onActivate={activateBuyAction}
                        >
                            <TradingButton.Essence
                                isTileSubscribed={shouldBeSubscribed}
                                isSell={false}
                                title={`Buy ${baseAsset}`}
                                priceToDisplay={buyPriceToDisplay}
                                priceWithTolerance={buyPriceWithTolerance}
                            />
                        </TradingButton>
                    </TileActions>
                    <AuthorizedClientTileInfo
                        isTileActive={isActive}
                        authorizedClientId={authorizedClientId}
                    />
                </Wrapper>
            </TileLayout>
            <ErrorsContainer>
                {[
                    errors.sizeOrVolume?.message,
                    errors.price?.message,
                    errors.type?.message,
                    errors.tolerance?.message,

                    errors.root?.api?.message,
                ]
                    .filter((errMsg) => Boolean(errMsg))
                    .map((errMsg) => (
                        <TileError key={errMsg}>{errMsg}</TileError>
                    ))}
            </ErrorsContainer>
        </Container>
    );
};

import { Icons } from "@fm-frontend/uikit";
import { CoinIcon } from "components/CoinIcon";
import { IconCheckmarkCurrent, IconChevronRight } from "components/icons";
import { Popover } from "components/Popover";
import { InstrumentIcon } from "feature/assetsControl/components/InstrumentsTabContent/InstrumentIcon";
import { DummyButton } from "feature/form/Buttons";
import { SelectedInstrumentContext } from "feature/trade/trading/SelectedInstrumentContext";
import { DropdownDirection } from "hooks/useDropdownDirection";
import { useSelector } from "hooks/useSelector";
import { TransitionState, useItemTransition } from "hooks/useTransition";
import React, { MutableRefObject, ReactNode, useContext, useMemo, useRef, useState } from "react";
import { Spacer } from "style";
import styled, { useTheme } from "styled-components";
import { TradingAsset } from "types";
import { useInstrumentSelectorContext } from "./InstrumentSelector";
import {
    Line,
    List,
    ListBanner,
    ListContainer,
    ListItem,
    ListSectionLabel,
    ListSeparatorItem,
    MatchedPart,
} from "./style";

export const ListSectionSeparator = () => {
    return (
        <ListSeparatorItem>
            <Line />
        </ListSeparatorItem>
    );
};

const BookmarkIcon = styled(Icons.Bookmark)<{ $isActive: boolean }>`
    path {
        fill: ${(p) => (p.$isActive ? "#f0aa3b" : p.theme.colors.ui20)};
    }
    :hover {
        path {
            fill: #f0aa3b;
        }
    }
`;
const BookmarkButton = ({ instrument }: { instrument: string }) => {
    const theme = useTheme();
    const { favoriteInstruments, onInstrumentSelect, onInstrumentDeselect } =
        useInstrumentSelectorContext();
    const isActive = favoriteInstruments.includes(instrument);

    return (
        <DummyButton
            onClick={(ev) => {
                ev.stopPropagation();
                if (isActive) {
                    onInstrumentDeselect(instrument);
                } else {
                    onInstrumentSelect(instrument);
                }
            }}
        >
            <BookmarkIcon color={theme.colors.ui20} $isActive={isActive} />
        </DummyButton>
    );
};

type Item = {
    title: string;
    icon?: ReactNode;
    children?: string[] | { base: string[]; quote: string[] };
};

type ItemList = Item[][];

export const SecondaryPopover = ({
    transitionState,
    horizontal,
    vertical,
    items,
    popoverRef,
    isCompact,
    onSelect,
    currentSubgroup,
    currentGroup,
}: {
    transitionState: TransitionState;
    items: string[] | { title: string; match: ReactNode }[] | { base: string[]; quote: string[] };
    popoverRef?: MutableRefObject<any>;
    isCompact?: true;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    currentSubgroup?: "base" | "quote";
    currentGroup?: string;
} & Partial<DropdownDirection>) => {
    const theme = useTheme();
    const isMobile = useSelector((state) => state.app.isMobile);
    const { instrument } = useContext(SelectedInstrumentContext);
    const content = useMemo(() => {
        if (Array.isArray(items)) {
            const contentByItems = items.map((item) => {
                const title = typeof item === "string" ? item : item.title;
                return (
                    <Item
                        key={title}
                        isCompact={isCompact}
                        type="base"
                        title={title}
                        onSelect={onSelect}
                        parent={currentGroup}
                        match={typeof item === "string" ? undefined : item.match}
                        isCurrent={currentSubgroup && item === instrument}
                    />
                );
            });
            if (contentByItems.length === 0) return null;
            return contentByItems;
        }
        const contentByItems = [
            ...(items.base.length
                ? [
                      <ListSectionLabel key="label-base" isCurrent={currentSubgroup === "base"}>
                          IS <mark>BASE-</mark>
                          {currentSubgroup === "base" && (
                              <IconCheckmarkCurrent color={theme.colors.brand100} />
                          )}
                      </ListSectionLabel>,
                      <ListSectionSeparator key={`separator-base`} />,
                  ]
                : []),
            ...items.base.map((item) => (
                <Item
                    key={item}
                    isCompact={isCompact}
                    title={item}
                    type="quote"
                    onSelect={onSelect}
                    isCurrent={currentSubgroup && item === instrument}
                />
            )),
            ...(items.quote.length
                ? [
                      <ListSectionLabel key="label-quote" isCurrent={currentSubgroup === "quote"}>
                          IS <mark>-QUOTE</mark>
                          {currentSubgroup === "quote" && (
                              <IconCheckmarkCurrent color={theme.colors.brand100} />
                          )}
                      </ListSectionLabel>,
                      <ListSectionSeparator key={`separator-quote`} />,
                  ]
                : []),
            ...items.quote.map((item) => (
                <Item
                    key={item}
                    isCompact={isCompact}
                    title={item}
                    type="base"
                    onSelect={onSelect}
                    isCurrent={currentSubgroup && item === instrument}
                />
            )),
        ];
        if (contentByItems.length === 0) return null;
        return contentByItems;
    }, [items, currentSubgroup, currentGroup, instrument, onSelect, isCompact]);
    return (
        <ListContainer
            isCompact={isCompact}
            ref={popoverRef}
            horizontal={horizontal || "right"}
            vertical={vertical || "center"}
            transitionState={transitionState}
            isMobile={isMobile}
            hasHeader={!Array.isArray(items)}
        >
            {content ? (
                <List>{content}</List>
            ) : (
                <ListBanner>
                    <header>Asset code not found</header>
                    <div>Please, use asset code like “BTC” or “ETH”.</div>
                </ListBanner>
            )}
        </ListContainer>
    );
};

const Item = ({
    title,
    icon,
    children,
    isSelected,
    onHover,
    popoverRef,
    onSelect,
    isCurrent,
    type,
    isCompact,
    match,
}: Item & {
    isSelected?: boolean;
    onHover?: () => void;
    popoverRef?: React.MutableRefObject<any>;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    isCurrent?: boolean;
    type?: "base" | "quote";
    isCompact?: boolean;
    parent?: string;
    match?: ReactNode;
}) => {
    const ref = useRef(null);
    const { isActive, transitionState } = useItemTransition(isSelected, {
        enterDelay: 100,
        exitDelay: 100,
    });
    const childItems = useMemo(() => {
        if (Array.isArray(children)) return children.length;
        if (children) return children.base.length + children.quote.length;
        return 0;
    }, [children]);
    const coin = useMemo(() => {
        if (type) {
            const parts = title.split("-");
            return (
                <InstrumentIcon coinSize={20} balanceCurrency={parts[1]} assetCurrency={parts[0]} />
            );
        }
        return <CoinIcon coin={title} size={20} />;
    }, [title, type]);
    const { asset, isReversed } = useContext(SelectedInstrumentContext);
    const isCurrentItem = useMemo(() => {
        if (!type) return asset?.toLowerCase() === title.toLowerCase();
        return false;
    }, [asset]);
    return (
        <ListItem
            ref={ref}
            isSelected={isSelected || false}
            isCurrent={isCurrent || isCurrentItem}
            onMouseOver={onHover}
            onTouchEnd={onHover}
            onClick={() => {
                if (!type) return;

                const parts = title.split("-");
                const reversed = type === "base";
                onSelect?.({ asset: parts[reversed ? 1 : 0], isReversed: reversed }, title);
            }}
            isCompact={isCompact}
        >
            <div className="icon">{icon || coin}</div>
            {match ? <MatchedPart>{match}</MatchedPart> : <span>{title}</span>}
            <Spacer />
            <span>
                {children ? (
                    <>
                        {childItems} <IconChevronRight />
                    </>
                ) : (
                    <BookmarkButton instrument={title} />
                )}
            </span>
            {isActive && children && (
                <Popover containerRef={ref}>
                    <SecondaryPopover
                        isCompact
                        transitionState={transitionState}
                        items={children}
                        popoverRef={isSelected ? popoverRef : undefined}
                        onSelect={onSelect}
                        currentSubgroup={
                            isCurrentItem ? (isReversed ? "quote" : "base") : undefined
                        }
                        currentGroup={title}
                    />
                </Popover>
            )}
        </ListItem>
    );
};

export const PrimaryPopover = ({
    transitionState,
    horizontal,
    vertical,
    items,
    popoverRef,
    popoverSubRef,
    onSelect,
    onItemHover,
}: {
    transitionState: TransitionState;
    items: ItemList;
    popoverRef: React.MutableRefObject<any>;
    popoverSubRef: React.MutableRefObject<any>;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    onItemHover?: (item: string) => void;
} & DropdownDirection) => {
    const [selectedItem, setSelectedItem] = useState("");
    const isMobile = useSelector((state) => state.app.isMobile);
    const content = useMemo(() => {
        return items.reduce<ReactNode[]>((acc, itemList, groupIdx) => {
            if (groupIdx > 0) acc.push(<ListSectionSeparator key={`separator-${groupIdx}`} />);
            acc.push(
                ...itemList.map((item, idx) => {
                    const itemIdx = `${groupIdx}-${idx}`;
                    return (
                        <Item
                            key={itemIdx}
                            {...item}
                            popoverRef={popoverSubRef}
                            isSelected={selectedItem === itemIdx}
                            onHover={() => {
                                setSelectedItem(itemIdx);
                                onItemHover?.(item.title);
                            }}
                            onSelect={onSelect}
                        />
                    );
                }),
            );
            return acc;
        }, []);
    }, [items, selectedItem, onSelect]);
    return (
        <ListContainer
            horizontal={horizontal}
            vertical={vertical}
            transitionState={transitionState}
            isMobile={isMobile}
            ref={popoverRef}
        >
            <List>{content}</List>
        </ListContainer>
    );
};

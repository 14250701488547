import { Flex, Icons, Loading, Tooltip } from "@fm-frontend/uikit";
import { SIZE64 } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { EmDash } from "const";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import { RfqHistoryItem, StatusOfRfqHistoryItem } from "feature/rfq/apiHooks/useLastRfqTrades";
import { useInstruments } from "hooks";
import { useSize64AssetFormatHelpers } from "hooks/useSize64AssetFormatHelpers";
import styled, { useTheme } from "styled-components";

const columnHelper = createColumnHelper<RfqHistoryItem>();

const SIDE_TITLES: Record<RfqHistoryItem["side"], string> = {
    BUY: "Buy",
    SELL: "Sell",
};

const useStatusSettings = (): Record<
    StatusOfRfqHistoryItem,
    { title: string; color: string; bgColor: string }
> => {
    const { colors } = useTheme();

    return {
        CREATED: {
            title: "Created",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        CANCELED: {
            title: "Cancelled",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        EXPIRED: {
            title: "Expired",
            color: colors.ui52,
            bgColor: colors.ui4,
        },
        COMMITTED: {
            title: "Executed",
            color: colors.positive100,
            bgColor: colors.positive4,
        },
    };
};

const StatusSpecificContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    line-height: 16px;
    padding: 0 4px;
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.ui4};
    height: 16px;
`;

const CancelIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8482_14649)">
            <path
                d="M12.0001 10.8003L9.20004 8.00022L12.0001 5.20016C12.3601 4.84014 12.3601 4.36012 12.0001 4.00011C11.6401 3.6401 11.1601 3.6401 10.8001 4.00011L8 6.80018L5.19993 4.00011C4.83992 3.6401 4.3599 3.6401 3.99989 4.00011C3.63988 4.36012 3.63988 4.84014 3.99989 5.20016L6.79996 8.00022L3.99989 10.8003C3.63988 11.1603 3.63988 11.6403 3.99989 12.0003C4.3599 12.3603 4.83992 12.3603 5.19993 12.0003L8 9.20027L10.8001 12.0003C11.1601 12.3603 11.6401 12.3603 12.0001 12.0003C12.3601 11.6403 12.3601 11.1603 12.0001 10.8003Z"
                fill="#243034"
                fill-opacity="0.32"
            />
        </g>
        <defs>
            <clipPath id="clip0_8482_14649">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
const Cancelled = () => {
    return (
        <Tooltip content="Cancelled">
            <StatusSpecificContainer>
                <CancelIcon />
            </StatusSpecificContainer>
        </Tooltip>
    );
};

const CommittedContainer = styled(StatusSpecificContainer)`
    background-color: ${(p) => p.theme.colors.positive8};
`;
const Committed = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Executed">
            <CommittedContainer>
                <Icons.Checkmark color={theme.colors.positive100} />
            </CommittedContainer>
        </Tooltip>
    );
};

const CreatedContainer = styled(StatusSpecificContainer)`
    background-color: ${(p) => p.theme.colors.process4};
    padding: 0 7px;
`;
const Created = () => {
    const theme = useTheme();

    return (
        <Tooltip content="Created">
            <CreatedContainer>
                <Loading size={10} strokeWidth={1.5} color={theme.colors.process100} />
            </CreatedContainer>
        </Tooltip>
    );
};

const ExpiredIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C5.51472 12.5 3.5 10.4853 3.5 8C3.5 5.51472 5.51472 3.5 8 3.5C10.4853 3.5 12.5 5.51472 12.5 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8.5 5.625C8.5 5.27982 8.16421 5 7.75 5C7.33579 5 7 5.27982 7 5.625V8.38388L8.71967 9.81694C9.01256 10.061 9.48744 10.061 9.78033 9.81694C10.0732 9.57286 10.0732 9.17714 9.78033 8.93306L8.5 7.86612V5.625Z"
            fill="#243034"
            fill-opacity="0.32"
        />
    </svg>
);
const Expired = () => (
    <Tooltip content="Expired">
        <StatusSpecificContainer>
            <ExpiredIcon />
        </StatusSpecificContainer>
    </Tooltip>
);

const StatusContainer = styled.div<{ $color: string; $bgColor: string }>`
    display: inline-block;
    padding: 0 2px;
    border-radius: 4px;
    color: ${({ $color }) => $color};
    background-color: ${({ $bgColor }) => $bgColor};
`;
export const Status = ({ status }: { status: StatusOfRfqHistoryItem }) => {
    const settingsObj = useStatusSettings();

    if (status === "CREATED") {
        return <Created />;
    }
    if (status === "CANCELED") {
        return <Cancelled />;
    }
    if (status === "COMMITTED") {
        return <Committed />;
    }
    if (status === "EXPIRED") {
        return <Expired />;
    }

    const { title, color, bgColor } = settingsObj[status];

    return (
        <StatusContainer $color={color} $bgColor={bgColor}>
            {title}
        </StatusContainer>
    );
};

const InstrumentCell = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
`;
const SidePriceCell = styled.div<{ $side: RfqHistoryItem["side"] }>`
    color: ${({ $side, theme: { colors } }) =>
        $side === "BUY" ? colors.positive100 : colors.negative100};
    font-size: 12px;
    line-height: 16px;
`;
const SizeCell = styled.div`
    font-size: 12px;
    line-height: 16px;
`;

const DateTimeCellContainer = styled(Flex)`
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    flex-direction: column;
`;
const Time = styled.span`
    color: ${(p) => p.theme.colors.ui100};
`;
const Date = styled.span`
    color: ${(p) => p.theme.colors.ui52};
`;
const DateTimeCell = ({ dateTime }: { dateTime: number }) => {
    const timeStr = format(dateTime, "HH:mm:ss");

    if (isToday(dateTime)) {
        return (
            <DateTimeCellContainer>
                <Time>{timeStr}</Time>
            </DateTimeCellContainer>
        );
    }

    const dateStr = format(dateTime, "dd LLL");

    return (
        <DateTimeCellContainer>
            <Time>{timeStr}</Time>
            <Date>{dateStr}</Date>
        </DateTimeCellContainer>
    );
};

export const tableColumns = [
    columnHelper.accessor((row) => row.date, {
        id: "time",
        cell: (rowData) => {
            const createdAt = rowData.getValue();
            return <DateTimeCell dateTime={createdAt} />;
        },
        header: "Time",
        meta: {
            cellStyleProps: {
                width: "48px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.instrumentName, {
        id: "instrument",
        cell: (rowData) => {
            const instrumentName = rowData.getValue();
            return <InstrumentCell>{instrumentName}</InstrumentCell>;
        },
        header: "Instrument",
        meta: {
            cellStyleProps: {
                width: "110px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row.side, {
        id: "side",
        cell: (rowData) => {
            const side = rowData.getValue();
            return <SidePriceCell $side={side}>{SIDE_TITLES[side]}</SidePriceCell>;
        },
        header: "Side",
        meta: {
            cellStyleProps: {
                width: "36px",
                textAlign: "left",
            },
            headerStyleProps: {
                textAlign: "left",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "price",
        cell: (rowData) => {
            const { side, price } = rowData.getValue();
            if (price === 0n) {
                return EmDash;
            }
            return (
                <SidePriceCell $side={side}>{SIZE64.toFormattedDecimalString(price)}</SidePriceCell>
            );
        },
        header: "Price",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "size",
        cell: (rowData) => {
            const { size, instrumentName } = rowData.getValue();
            const { intrumentsByNameObj } = useInstruments();
            const { toFormattedDecimalStringByAsset } = useSize64AssetFormatHelpers();
            return (
                <SizeCell>
                    {toFormattedDecimalStringByAsset(
                        size,
                        intrumentsByNameObj[instrumentName]?.assetCurrency,
                    )}
                </SizeCell>
            );
        },
        header: "Size",
        meta: {
            cellStyleProps: {
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (rowData) => {
            const status = rowData.getValue();

            return <Status status={status} />;
        },
        header: "Status",
        meta: {
            cellStyleProps: {
                width: "75px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
];

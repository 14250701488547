import { P, PBold } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { TradeHistory } from "feature/history/types";
import React from "react";
import { fmt, FormatConfig } from "utils/format";

type PriceCellProps = Pick<TradeHistory, "price"> & {
    isExpandableRow?: boolean;
};

export const priceCellFormatConfig: FormatConfig = {
    significantDigits: 8,
    type: "price",
    showZero: true,
};

export const PriceCell: React.FC<PriceCellProps> = ({ price, isExpandableRow }) => {
    if (price === undefined) {
        return <>{EmDash}</>;
    }

    const fmtPrice = fmt(price, priceCellFormatConfig);

    const Element = isExpandableRow ? PBold : P;

    return <Element>{fmtPrice.formattedValue}</Element>;
};

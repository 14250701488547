import { PBold } from "@fm-frontend/uikit";
import { EmDash } from "const";
import { TradeHistory } from "feature/history/types";
import React from "react";

type InstrumentCellProps = Pick<TradeHistory, "instrument" | "isSubrow">;
export const InstrumentCell: React.FC<InstrumentCellProps> = ({ instrument, isSubrow }) => {
    if (instrument === undefined) {
        return <>{EmDash}</>;
    }
    if (instrument === null || isSubrow) {
        return <></>;
    }

    return <PBold textWrap="nowrap">{instrument}</PBold>;
};

import {
    Accordion,
    ACTIONS_TABLE_COLUMN_KEY,
    HStack,
    IconButton,
    Icons,
    Tab,
    TabContext,
    TabList,
    Tooltip,
} from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { useCreatePrefilledTransactionOrRequest } from "hooks";
import { useMemo, useState } from "react";
import { GroupBy, usePositionsTableData } from "store/usePositionsGrouped";
import { Spacer } from "style";
import styled from "styled-components";
import { CommonPositionsAccordion } from "./CommonPositionsAccordion";
import { PositionsAccordionContent } from "./PositionsAccordionContent";
import { columnHelper } from "./PositionsAccordionContent/PositionsTable/table";
import { isSubrow } from "./PositionsAccordionContent/utils";

const TabsContainer = styled.div`
    min-width: 270px;
`;

const PositionsAccordionHeader = styled(HStack)`
    gap: 8px;
    align-items: center;
`;

const { HRP_MASTER_ID } = getEnv();

export const PositionsAccordion = () => {
    const [groupBy, setGroupBy] = useState<GroupBy>("counterparty");
    const { totalUSD, groupedPositionsData, isLoading } = usePositionsTableData({
        groupBy,
        includeSettlementOrders: true,
    });
    const noPositions = !isLoading && Object.keys(groupedPositionsData).length === 0;
    const header = (
        <PositionsAccordionHeader>
            <TabsContainer
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                <TabContext value={groupBy} handleClick={setGroupBy}>
                    <TabList size="small">
                        <Tab
                            title="Positions by Entities"
                            value="counterparty"
                            key="counterparty"
                        />
                        <Tab title="Positions by Assets" value="asset" key="asset" />
                    </TabList>
                </TabContext>
            </TabsContainer>
            {noPositions && (
                <>
                    <Spacer />
                    <Accordion.EmptyLabel>No open positions</Accordion.EmptyLabel>
                </>
            )}
        </PositionsAccordionHeader>
    );

    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    const actionColumn = useMemo(
        () =>
            columnHelper.accessor((row) => row, {
                id: ACTIONS_TABLE_COLUMN_KEY,
                header: "",
                cell: (rowData) => {
                    const rowValue = rowData.getValue();

                    if (isSubrow(rowValue)) {
                        const { cpId, asset, value } = rowValue;

                        if (cpId === HRP_MASTER_ID) {
                            return null;
                        }

                        return (
                            <Tooltip content="Settle">
                                <IconButton
                                    variant="plain"
                                    Icon={rowValue.value < 0 ? Icons.ArrowUp : Icons.ArrowReply}
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        createPrefilledTransactionOrRequest({
                                            cpId,
                                            asset,
                                            value,
                                        });
                                    }}
                                />
                            </Tooltip>
                        );
                    }

                    return null;
                },
                meta: {
                    cellStyleProps: {
                        width: "40px",
                        textAlign: "right",
                        padding: "0 8px 0 0",
                    },
                },
            }),
        [createPrefilledTransactionOrRequest],
    );

    const content = (
        <PositionsAccordionContent
            groupBy={groupBy}
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            actionColumn={actionColumn}
        />
    );

    return (
        <CommonPositionsAccordion
            totalUSD={totalUSD}
            groupedPositionsData={groupedPositionsData}
            isLoading={isLoading}
            content={content}
            header={header}
        />
    );
};
